import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "扩散系数", content: "高" },
  {
    color: "bg-case_orange",
    title: "抽签法",
    content: "速赢（即开型彩票）",
  },
  { color: "bg-accent", title: "如何检查结果", content: "DM（仅限获奖者）" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "第一次组织运动" },
  { title: "组织运动的预算低" },
  { title: "我希望我的推文能够传播" },
  { title: "我想应用一个胜利的过滤器" },
  { title: "增加追随者的数量应该是一个首要目标" },
  { title: "发送数字礼券，省去送礼的麻烦" },
  { title: "想分发自己的优惠券代码以促进销售" },
]

const NonInstantWinDigitalGift = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="Twitter活动案例研究，自动发送礼品券给转发者。"
          pagepath={location.pathname}
          pagedesc="只要关注活动组织者的推特账户并转发指定的帖子，就可以参加该活动。 这项活动在增加推特粉丝和提高知名度方面非常有效。"
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                关注和转发并发送礼券
                <br className="block" />
                （即时赢）
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                只需关注主办方账户并转发指定帖子即可完成参赛。
                <br />
                该系统可以完全自动化，从现场抽奖到数字礼品券代码的即时DM传输。
                <br />
                它对增加追随者的数量非常有效。
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <StaticImage
                  layout="fullWidth"
                  alt="关注和转发并发送礼券（即时赢）"
                  src="../../../../images/case_study/mv01.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                推荐使用
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                运动的特点
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      参与者
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        扩散系数
                      </p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        参与的便利性
                      </p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">激励</p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      联络人
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">缓解</p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  这是一个非常简单而有效的增加追随者的活动。
                  <br />
                  <br />
                  只需点击两下就可以关注和转发，用户参与的门槛很低，而且高度分散。
                  <br />
                  <br />
                  除了发送其他公司的服务代码，如亚马逊礼券和Quo Card
                  Pay，你也可以发送你自己的代金券代码来推广你的公司。
                  <br />
                  <br />
                  用户参与的积极性很高，因为数字礼券代码会立即通过DM发送至获奖者。
                  <br />
                  <br />
                  DM的传输是自动的，所以一旦活动开始，负责人就没有任何工作。
                  <br />
                  活动工具是必不可少的，因为获奖者的通知必须在被关注＆转发后立即发送给参与者。
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                这与普通的关注和转发活动有什么不同？
              </h2>
              <p className="mb-6 text-text">
                即时赢需要使用活动工具，事实上，与人工关注和转发活动相比，引入活动工具可以大大减少操作时间！
                <br />
                <br />
                此外，只有获奖者可以通过DM发送他们公司的优惠券代码，可以用来推广他们自己的产品。
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    如果活动是手动进行的
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      通过Twitter打开相关活动页面
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      查看参与者的名单
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          检查用户详情屏幕
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          获奖者的确定
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        有多少个赢家就重复多少次
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        搜索获奖者的账户
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        DM的创建、确认和传输
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        有多少个赢家就重复多少次
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-text mb-3">
                      仅仅完成运动就
                      <br className="block md:hidden" />
                      <span className="font-bold text-2xl text-accent">
                        需要3-5小时
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        需要登录Twitter，
                        <span className="font-bold">
                          逐一查看每个参与者的资料
                        </span>
                      </li>
                      <li>
                        需要
                        <span className="font-bold">逐一给获奖者发送DM</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      当使用PARKLoT时
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        从管理界面注册活动数据和数字礼券代码
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        活动自动开始，并自动吸引转发的用户
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        当获奖者被选中时，会自动发送DM，
                        <br />
                        并附上数字礼券代码。
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        活动实施过程只需
                        <br className="md:hidden block" />
                        <span className="text-2xl text-accent leading-normal">
                          20-30分钟!
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          参与者的名单可
                          <span className="font-bold">
                            以通过CSV从管理界面下载
                          </span>
                        </li>
                        <li>
                          抽出赢家和输家的过程可
                          <span className="font-bold">以完全自动化</span>
                        </li>
                        <li>
                          （作为次要效果）
                          <span className="font-bold">
                            可以对申请人的趋势进行分析，并用于未来的活动
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      在以下情况下，
                      <br className="block md:hidden" />
                      不建议使用工具
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>不需要申请人的信息</li>
                        <li>更少的赢家</li>
                        <li>没有实施工具的费用</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                使用PARKLoT时的
                <br className="block md:hidden" />
                具体运动程序
              </h2>
              <FlowNumber
                num="01"
                parklot={false}
                title="请在管理屏幕上登记你的运动信息"
              >
                <p className="text-text">
                  请在活动开始前至少三个工作日通过管理页面登记你的活动信息。
                  <br />
                  所需的数据可以在
                  <a
                    className="text-accent underline"
                    href="https://xhackttl.notion.site/DM-c644052ee2d74a9986fea388ba70f0df"
                    target="_blank"
                    rel="noreferrer"
                  >
                    [关注与转发活动和数字礼券DM分发]时间表和所需数
                  </a>
                  据中找到。
                </p>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={false}
                title="请提供CSV格式的数字礼券代码清单"
              >
                <p className="text-text">
                  请在活动开始前至少三个工作日提供CSV格式的数字礼券代码清单。
                  <br />
                  我们将把这些数据与运动数据联系起来。
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="活动推文自动发布，活动开始"
              >
                <p className="text-text">
                  在开始时间，推文会自动发布，活动开始。
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                last={true}
                title="当获奖者被选中时，我们将自动向你发送一个带有数字代码的DM"
              >
                <p className="text-text">
                  通过计算中奖概率，从转发用户中自动选出获奖者。
                  <br />
                  当获奖者被选中时，会自动向获奖者发送DM，告知他们的数字礼券代码。
                  <br />
                  <br />
                  活动参与者的名单可以从管理屏幕上查看。
                </p>
              </FlowNumber>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoT的月费
                <br className="block md:hidden" />
                中包括哪些功能？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                在月费范围内，可以进行一般的即赢和数字礼品券代码发送活动。
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                月费中包含的功能
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 md:justify-around">
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="即时赢取运动的实施成本"
                    src="../../../../images/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    即时赢取运动的实施成本
                  </h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt=" 参与者信息的CSV输出"
                    src="../../../../images/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    参与者信息的CSV输出
                  </h4>
                  <p className="text-text text-xs">
                    *如果需要进行复杂的定制，
                    <br />
                    可能会收取额外费用
                  </p>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="将获奖者自动提取的数字礼券代码自动传送到DM。"
                    src="../../../../images/case_study/parklot_function03.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    自动提取获胜者
                    <br />
                    自动发送数字礼品券代码的DM
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default NonInstantWinDigitalGift
